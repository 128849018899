<template>
	<div>
		<h4>Something has gone wrong!</h4>

		<p>Here are some things you can try:</p>
		<ul>
			<li>
				Attempt to
				<router-link :to="{ name: 'Logout', params: {} }">
					Logout
				</router-link>
			</li>

			<li>Clear your browser's cookies</li>
			<li>Contact your system adminstrator</li>
		</ul>
	</div>
</template>

<script>
export default {
	name: "Error"
};
</script>

<style scoped>
h4 {
	text-align: center;
}
</style>
